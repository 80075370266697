import Vue from 'vue'
import App from './App.vue'
import router from '@/router/news'
import global from '@/utlis/global.js'
import store from '@/store'
import '@/utlis/remcommon.js'
// import VueLazyload from 'vue-lazyload'
import { initializeApp} from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import Beyla from '@shareit/beyla'
Vue.config.productionTip = false
Vue.prototype.$global = global
// const loadimage = require('@/assets/news/loading.png')
Vue.prototype.$api = global.api
import '../../utlis/vant'
// Vue.use(Meta)
// Vue.use(VueLazyload, {
// 	preLoad: 1.3,
// 	error: loadimage,
// 	loading: loadimage,
// 	attempt: 1,
// })
// firebase
const firebaseConfig =  {
  apiKey: "AIzaSyA_deiO3J03dr2s-iuvGUCv6HMHFqduIPc",
  authDomain: "news-h5.firebaseapp.com",
  projectId: "news-h5",
  storageBucket: "news-h5.appspot.com",
  messagingSenderId: "543685424336",
  appId: "1:543685424336:web:18ce043e12a4e63f9b9ad5",
  measurementId: "G-Z5DRRRNVT6"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
Vue.prototype.$eventrackFb = (msg, method,map = {}) => {
  let params = {
    time: new Date(),
    message: msg,
     method: method,
    ...map,
  };
  console.log(params,'firebase')
  logEvent(analytics, msg, params);
};

//Beyla
const beylaInstance = new Beyla({
	appId: `news-h5`,
	project: 'fe-analysis-data', //上报到metis项目名 创建项目时的名称 不能"_"
	logStore: 'report', //metis创建日志里的日志名称 不能"_"
	env: process.env.VUE_APP_REPORT_SDK_ENV, //环境
	groupName: process.env.VUE_APP_REPORT_SDK_GROUPNAME,
	strict: false,
})
Vue.prototype.$beylaInstance = beylaInstance

Vue.prototype.$logEvent = (event, params) => {
  logEvent(analytics, event, params);
};
Vue.prototype.$eventrack = (msg, method, map = {}) => {
	let params = {
		time: new Date(),
		message: msg,
		method: method,
		...map,
	}
	console.log(params,'Beyla');
	beylaInstance.report(params)
}
new Vue({                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            
  router,
  store,
  render: h => h(App),
}).$mount('#app')
